import Vue from "vue";
import Snackbar from "@/store/modules/snackbarModule";

export default Vue.extend({
  name: "AppSnackbarComponent",
  computed: {
    snack: {
      get() {
        return Snackbar.snackbar;
      },
      set() {
        Snackbar.Hide();
      },
    },
    snackMsg(): string {
      return Snackbar.errorMsg;
    },
    snackColor(): string {
      return Snackbar.snackColor;
    },
  },
  methods: {
    HideSnack() {
      Snackbar.Hide();
    },
  },
});
